import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { BrowserRouter as Router, Route, Routes, Redirect, Navigate, useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; // Add this line at the top
import ItemDetailsPage from './ItemDetailsPage';
import InvoiceDetailsPage from './InvoiceDetailsPage';
import WelcomePage from './WelcomePage';
import LoggedInPage from './LoggedInPage';

import InvoicesPage from './InvoicesPage';
import ItemsPage from './ItemsPage';

import backgroundImage from './taskpadbackground-min.png';
import taskPadIcon from './assets/favicon.png';

const auth0ClientId = 'VgA4YJueC2aEI3U7B1dxHpwOCrXRYHQR';
const auth0Domain = 'dev-psw7nj5r7hp6q2lc.us.auth0.com';

function App() {
  const [isLoading, setIsLoading] = useState(true); // Manage loading state

  const navigate = useNavigate(); // Hook for programmatic navigation
  const [userProfile, setUserProfile] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = useState('home');
  const goToHome = () => navigate('/');
  const goToInvoices = () => navigate('/invoices');
  const goToItems = () => navigate('/items');
  const fetchUserProfile = (accessToken) => {
    fetch(`https://${auth0Domain}/userinfo`, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })
    .then(response => response.json())
    .then(data => {
        console.log('User Profile Data:', data);
        if (data && data.email) {
            setUserProfile(data);
            setIsLoggedIn(true);
            setCurrentPage('profile');
            localStorage.setItem('userProfile', JSON.stringify(data));
            
            // After successful profile retrieval, send email to your backend
            checkOrCreateUser(data.email);
        } else {
            console.error('No email found in fetched data', data);
        }
    })
    .catch(error => {
        console.error('Error fetching user profile:', error);
    });
};
useEffect(() => {
  const checkAuthentication = async () => {
      const accessToken = localStorage.getItem('accessToken'); // Assume this is how you manage tokens
      if (accessToken) {
          try {
              const data = await fetchUserProfile(accessToken); // Fetch user details
              if (data && data.email) {
                  setIsLoggedIn(true);
                  setUserProfile(data);
              } else {
                  console.error('Authentication failed or data is incomplete');
              }
          } catch (error) {
              console.error('Failed to authenticate', error);
          }
      }
      setIsLoading(false); // Ensure loading state is updated regardless of the outcome
  };
// eslint-disable-next-line
  checkAuthentication();
  // eslint-disable-next-line
}, []);
const checkOrCreateUser = (email) => {
  fetch('https://skeba.info/costbrain/login.php', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email })
  })
  .then(response => response.json())
  .then(data => {
      console.log('Backend response for user creation:', data);
  })
  .catch(error => {
      console.error('Error creating/checking user:', error);
  });
};
  useEffect(() => {
    // Call on initial load as well
    const checkForAuthResponse = () => {
      const { hash } = window.location;
      if (hash.includes('access_token')) {
        console.log('Auth response found in hash:', hash);
        const params = new URLSearchParams(hash.substring(1)); 
        const accessToken = params.get('access_token');
        if (accessToken) {
          console.log('Access Token:', accessToken);
          localStorage.setItem('accessToken', accessToken); // Optionally store access token
          fetchUserProfile(accessToken);
        } else {
          console.log('No access token found');
        }
      }
    };
  
    checkForAuthResponse(); // Check for auth response on initial load
  
    window.addEventListener('hashchange', checkForAuthResponse, false);
  
    return () => {
      window.removeEventListener('hashchange', checkForAuthResponse, false);
    };
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    const loadProfileFromStorage = () => {
      const storedProfile = localStorage.getItem('userProfile');
      if (storedProfile) {
        setUserProfile(JSON.parse(storedProfile));
        setIsLoggedIn(true);
      }
    };

    loadProfileFromStorage();

    window.onpopstate = () => {
      const accessToken = new URLSearchParams(window.location.hash.slice(1)).get('access_token');
      if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        fetchUserProfile(accessToken);
      } else {
        setCurrentPage(window.location.pathname.slice(1));
      }
    };

    return () => {
      window.onpopstate = null;
    };
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const login = () => {
    window.location.href = `https://${auth0Domain}/authorize?` +
      `scope=openid%20profile%20email&` +
      `response_type=token&` +
      `client_id=${auth0ClientId}&` +
      `redirect_uri=${window.location.origin}`;
  };

  const logout = () => {
    // Clear application state
    setIsLoggedIn(false);
    setUserProfile(null);
    localStorage.removeItem('userProfile');
    localStorage.removeItem('accessToken');  // Ensure accessToken is also removed

    // Prepare Auth0 logout URL
    const returnTo = encodeURIComponent(window.location.origin);
    window.location.href = `https://${auth0Domain}/v2/logout?client_id=${auth0ClientId}&returnTo=${returnTo}`;
    // Note: navigate('/'); won't execute because window.location.href will reload the browser.
};

  return (

      <div style={{ background: 'url(' + backgroundImage + ') repeat center center fixed', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <div style={{ padding: '10px', backgroundColor: '#333', color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={taskPadIcon} alt="Logo" style={{ marginRight: '10px', width: '30px', height: '30px' }} />
            <span>CostBrain</span>
          </div>
          <div>
  {isLoggedIn ? (
    <>
      <button onClick={goToHome}>Home</button>
      <button onClick={goToInvoices}>Invoices</button>
      <button onClick={goToItems}>Items</button>
      <button onClick={logout}>Logout</button>
    </>
  ) : (
    <button onClick={login}>Log In</button>
  )}
</div>
        </div>
        {isLoading ? (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>Loading...</div>
        ) : (
            <Routes>
                         <Route path="/" element={
          isLoggedIn ?
          <LoggedInPage initialUserProfile={userProfile} /> :
          <WelcomePage login={login} />
        } />

                
                <Route path="/invoices" element={isLoggedIn ? <InvoicesPage userEmail={userProfile?.email} userProfile={userProfile} /> : <Navigate to="/" />} />
                <Route path="/items" element={isLoggedIn ? <ItemsPage userEmail={userProfile?.email} /> : <Navigate to="/" />} />
                <Route path="/items/:item_id" element={<ItemDetailsPage />} /> {/* Correct parameter handling */}
                <Route path="/invoices/:invoiceId" element={<InvoiceDetailsPage />} />


            </Routes>
        )}
    </div>
);

}

export default App;

