import React from 'react';
import './WelcomePage.css'; // Ensure your CSS is updated accordingly
import favicon from './assets/favicon.png'; // Import the favicon image

const WelcomePage = ({ login }) => {
    return (
        <div className="welcome-container">
                 <div className="welcome-text">
                 <div className="text-background">
                 <img src={favicon} alt="CostBrain Logo" className="logo-img" />
                 <h1 className="welcome-title">CostBrain</h1>

               
                <h4>Manage your restaurant's inventory costs with CostBrain.</h4>
                </div>
                </div>
            <div className="welcome-banner">
                <div className="signup-box">
                    <h2>Create a Free Account</h2>
                    <button onClick={login}>Sign Up</button>
                </div>
           
                
            </div>
            <p className="intro-text">CostBrain is designed for restaurants, chefs, and owners to track inventory costs across multiple suppliers effortlessly. It allows you to upload invoices, which are automatically parsed into data to help manage and track costs among your ingredients.</p>
            <div className="screenshot-gallery">
                <img src="invoice.png" alt="Dashboard View" />
                <img src="invoicelist.png" alt="Invoice Upload Feature" />
                <img src="item.png" alt="Cost Tracking Interface" />
                <img src="itemlist.png" alt="Supplier Management Screen" />
            </div>
        </div>
    );
};

export default WelcomePage;
