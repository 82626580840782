import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faBox } from '@fortawesome/free-solid-svg-icons';
import './LoggedInPage.css';
import { Link } from 'react-router-dom';
const LoggedInPage = ({ initialUserProfile, userEmail }) => {
  const [userProfile, setUserProfile] = useState(initialUserProfile || null);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const storedProfile = localStorage.getItem('userProfile');
      if (storedProfile) {
        setUserProfile(JSON.parse(storedProfile));
      } else {
        // Optionally fetch from server here if local storage is empty
        // setIsLoading(true);
        // const profile = await fetchUserProfileFromServer();
        // setUserProfile(profile);
        // setIsLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  useEffect(() => {
    console.log("Initial user profile:", initialUserProfile);
    if (initialUserProfile) {
      setUserProfile(initialUserProfile);
    } else {
      // Fallback logic if initialUserProfile is not provided initially
      const storedProfile = localStorage.getItem('userProfile');
      if (storedProfile) {
        setUserProfile(JSON.parse(storedProfile));
      } else {
        // Optionally add logic to fetch from a server here
      }
    }
  }, [initialUserProfile]);



  return (
    <div className="container d-flex flex-column align-items-center py-4">
      <div className="card p-4 shadow-sm">
        <h1 className="h4 mb-3">Welcome {userProfile?.name || 'User'}!</h1>
        <p>Email: {userProfile?.email || 'Not available'}</p>
        <div className="row mt-4">
          <div className="col">
            <Link to="/invoices" className="card text-center link-card">
              <div className="card-body">
                <FontAwesomeIcon icon={faFileInvoice} size="2x" />
                <p className="mt-2">Invoices</p>
              </div>
            </Link>
          </div>
          <div className="col">
            <Link to="/items" className="card text-center link-card">
              <div className="card-body">
                <FontAwesomeIcon icon={faBox} size="2x" />
                <p className="mt-2">Items</p>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggedInPage;