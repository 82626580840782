import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './InvoiceStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const InvoiceDetailsPage = () => {
    const { invoiceId } = useParams();
    const navigate = useNavigate();
    const [itemDetails, setItemDetails] = useState([]);
    const [invoiceDetails, setInvoiceDetails] = useState({
        invoiceNumber: '',
        invoiceDate: '',
        invoiceTotal: 0
    });
    const [editIndex, setEditIndex] = useState(null); // index of the item being edited
    const [editedItems, setEditedItems] = useState({}); // To store edited values
    const deleteItem = (index) => {
        const confirmed = window.confirm("Confirm delete?");
        if (confirmed) {
            const item = itemDetails[index];
            const item_id = item.Item_Id; // Ensure this is an integer
            const invoice_id = parseInt(invoiceId, 10); // Explicitly convert invoiceId to an integer
    
            fetch(`https://skeba.info/costbrain/itemsDisplay.php`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    Invoice_Id: invoice_id,
                    Item_Id: item_id
                })
            })
            .then(response => response.json())
            .then(data => {
                console.log('Delete response:', data);
                if (data.message) {
                    // Remove the item from the local state only if the server confirms deletion
                    const updatedItems = [...itemDetails];
                    updatedItems.splice(index, 1);
                    setItemDetails(updatedItems);
                    alert('Item deleted successfully');
                } else {
                    // Handle any errors or issues reported by the server
                    alert('Failed to delete item: ' + data.error);
                }
            })
            .catch(error => {
                console.error('Error deleting item:', error);
                alert('Error deleting item: ' + error.message);
            });
        }
    };
    
    
    useEffect(() => {
        const fetchItemDetails = async () => {
            try {
                const response = await fetch('https://skeba.info/costbrain/itemsDisplay.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ Invoice_Id: invoiceId })
                });

                const json = await response.json();
                if (json.Invoice_Details && json.Items) {
                    setInvoiceDetails({
                        invoiceNumber: json.Invoice_Details.Invoice_Number,
                        invoiceDate: json.Invoice_Details.Invoice_Date,
                        invoiceSupplier: json.Invoice_Details.Supplier,
                    });
                    setItemDetails(json.Items);
                }
            } catch (error) {
                console.error('Error fetching item details:', error);
            }
        };

        fetchItemDetails();
    }, [invoiceId]);

    const handleBackClick = () => {
        navigate(-1);
    };

    const startEdit = (index) => {
        setEditIndex(index);
        setEditedItems({
            ...editedItems,
            [index]: {
                editedName: itemDetails[index].Item_Name,
                editedUnit: itemDetails[index].Item_Unit,
                editedQty: itemDetails[index].Item_Qty,
                editedPrice: itemDetails[index].Item_Price,
            }
        });
    };

    const cancelEdit = () => {
        setEditIndex(null);
    };

    const saveEdit = (index) => {
        const item = editedItems[index];
        // Ensure quantity and price are converted to numbers
        const itemQty = Number(item.editedQty);
        const itemPrice = Number(item.editedPrice);
    
        const payload = {
            Item_Id: itemDetails[index].Item_Id,
            Invoice_Id: Number(invoiceId),  // Convert Invoice_Id to number
            Item_Name: item.editedName,
            Item_Unit: item.editedUnit,
            Item_Qty: itemQty,              // Use converted number
            Item_Price: itemPrice,          // Use converted number
        };
    
        fetch('https://skeba.info/costbrain/itemsDisplay.php', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload)
        })
        .then(response => response.json())
        .then(data => {
            console.log('Success:', data);
            // Update local state with new values ensuring types are consistent
            const updatedItems = [...itemDetails];
            updatedItems[index] = {
                ...updatedItems[index],
                Item_Name: item.editedName,
                Item_Unit: item.editedUnit,
                Item_Qty: itemQty,
                Item_Price: itemPrice,
            };
            setItemDetails(updatedItems);
            setEditIndex(null); // Exit edit mode
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    };
    
    

    const handleChange = (index, field, value) => {
        // Convert empty string to null (or you can use 0 or any default value)
        const validValue = value === '' ? null : value;
    
        setEditedItems({
            ...editedItems,
            [index]: {
                ...editedItems[index],
                [field]: validValue
            }
        });
    };
    
    const calculateTotalPrice = (Qty, Price) => {
        const total = Qty * Price;
        return `$${total.toFixed(2)}`;  // Format the total as a currency string
    };
    
    // Calculate the price change and format the output
    const calculatePriceChange = (currentPrice, previousPrice) => {
        if (previousPrice === null) {
            return 'N/A';  // No previous price available
        }
        const difference = currentPrice - previousPrice;
        const percentageChange = (difference / previousPrice) * 100;
    
        // If the percentage change rounds to zero, display a hyphen
        if (Math.round(percentageChange) === 0) {
            return '-';
        }
    
        const style = difference >= 0 ? { color: 'red' } : { color: 'green' };
        const arrow = difference >= 0 ? '↑' : '↓';
        return (
            <span style={style}>
                {Math.abs(Math.round(percentageChange))}% {arrow}
            </span>
        );
    };

    return (
        <div style={{ maxWidth: 600, margin: '0 auto' }} className="container mt-4">
            <div className="bg-white p-4 shadow rounded">
                <button onClick={handleBackClick} className="btn btn-primary mb-3">Back to Invoices</button>
                <h2>Invoice {invoiceDetails.invoiceNumber}</h2>
                <span>Date: {invoiceDetails.invoiceDate}</span>
                <p>Supplier: {invoiceDetails.invoiceSupplier}</p>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th class="tableItemName">Item</th>
                            <th>Unit</th>
                            <th>Qty</th>
                            <th>Price</th>
                            <th>Total</th>
                            <th>Actions</th>
                  
                        </tr>
                    </thead>
                    <tbody>
    {itemDetails.map((item, index) => (
        <tr key={index}>
            <td>
                {editIndex === index ? (
                    <input type="text" value={editedItems[index]?.editedName || item.Item_Name} onChange={e => handleChange(index, 'editedName', e.target.value)} className="form-control" />
                ) : (
                    <a href={`${process.env.REACT_APP_BASE_URL}/items/${item.Item_Id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {item.Item_Name}
                </a>
                )}
            </td>
            <td>
                {editIndex === index ? (
                    <input type="text" value={editedItems[index]?.editedUnit || item.Item_Unit} onChange={e => handleChange(index, 'editedUnit', e.target.value)} className="form-control width60" />
                ) : (
                 
                    item.Item_Unit
           
                )}
            </td>
            <td>
                {editIndex === index ? (
                   <input type="number"
                   value={editedItems[index]?.editedQty !== null ? editedItems[index].editedQty : ''}
                   onChange={e => handleChange(index, 'editedQty', e.target.value)}
                   className="form-control width60" />
                ) : item.Item_Qty}
            </td>
            <td>
                {editIndex === index ? (
                  <input type="number"
                  value={editedItems[index]?.editedPrice !== null ? editedItems[index].editedPrice : ''}
                  onChange={e => handleChange(index, 'editedPrice', e.target.value)}
                  className="form-control width60" />
                ) : `$${item.Item_Price}`}
                     <span class="priceChange">  {calculatePriceChange(item.Item_Price, item.Previous_Item_Price)}</span>
            </td>
            <td>
            <td>{calculateTotalPrice(item.Item_Price, item.Item_Qty)}</td>

            </td>
            <td>
                {editIndex === index ? (
                    <div>
                        <button className="btn btn-success actionButton" onClick={() => saveEdit(index)}><FontAwesomeIcon icon={faSave} /></button>
                        <button className="btn btn-danger actionButton actionButtonSpace" onClick={cancelEdit}><FontAwesomeIcon icon={faTimes} /></button>
                    </div>
                ) : (
                    <div>
                        <button className="btn btn-primary actionButton" onClick={() => startEdit(index)}><FontAwesomeIcon icon={faEdit} /></button>
                        <button className="btn btn-danger actionButton actionButtonSpace" onClick={() => deleteItem(index)}><FontAwesomeIcon icon={faTrashAlt} /></button>
                    </div>
                )}
            </td>
      
        </tr>
    ))}
</tbody>

                </table>
            </div>
        </div>
    );
};

export default InvoiceDetailsPage;

