import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const ItemsPage = ({ userEmail }) => {
    const [items, setItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    // eslint-disable-next-line
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [searchQuery, setSearchQuery] = useState("");
    const navigate = useNavigate();

    const fetchItems = useCallback(async () => {
        const response = await fetch('https://skeba.info/costbrain/allUserItems.php', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: userEmail })
        });
        const json = await response.json();
        json.sort((a, b) => a.Item_Name.localeCompare(b.Item_Name));
        setItems(json);
    }, [userEmail]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems]);

    useEffect(() => {
        setCurrentPage(1); // Reset to the first page when search query changes
    }, [searchQuery]);

    const filteredItems = items.filter(item =>
        item.Item_Name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const totalItems = filteredItems.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleItemClick = (item) => {
        navigate(`/items/${item.Item_Id}`);
    };

    const calculateChange = (currentPrice, previousPrice) => {
        if (!previousPrice) {
            return '-';
        }
        const difference = currentPrice - previousPrice;
        const changePercent = (difference / previousPrice) * 100;
        if (difference === 0) {
            return '-';
        }
        return (
            <span style={{ color: difference > 0 ? 'red' : 'green' }}>
                {Math.abs(changePercent).toFixed(2)}% {difference > 0 ? '↑' : '↓'}
            </span>
        );
    };

    return (
        <div style={{ maxWidth: 600, margin: '0 auto' }} className="container mt-4 bg-white p-4 shadow rounded">
            <h2 className="mb-3 text-center">Item List</h2>
            <input
                type="text"
                className="form-control mb-4"
                placeholder="Search items..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />
            <table className="table">
            <thead>
                    <tr>
                        <th>Item Name</th>
                        <th>Current Price</th>
                        <th>Change</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item, index) => (
                        <tr key={index}>
                            <td className="clickable-item" onClick={() => handleItemClick(item)}>
                                {item.Item_Name}
                            </td>
                            <td>${item.Current_Price}</td>
                            <td>{calculateChange(item.Current_Price, item.Previous_Price)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {totalPages > 1 && (
                <nav>
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, i) => (
                            <button key={i} onClick={() => paginate(i + 1)} className={`page-link ${currentPage === i + 1 ? 'active' : ''}`}>
                                {i + 1}
                            </button>
                        ))}
                    </div>
                </nav>
            )}
        </div>
    );
};

export default ItemsPage;


