
import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
// eslint-disable-next-line
import { useNavigate, useLocation } from 'react-router-dom';
import './InvoiceStyles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';



const InvoicesPage = ({ userEmail }) => {
    const navigate = useNavigate();
    const [invoices, setInvoices] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
// eslint-disable-next-line
    const [total, setTotal] = useState(0);
    const [sortOrder, setSortOrder] = useState('desc');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [supplierName, setSupplierName] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [invoicesPerPage] = useState(10);
    const fileInputRef = useRef(null);
    const [uploadMessage, setUploadMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);

    const handleChooseFile = () => {
        fileInputRef.current && fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setUploadMessage('');
        }
    };
    const uploadFile = async () => {
        if (selectedFile && supplierName) {  // Ensure that a supplier name is also selected
            setIsLoading(true);
            const formData = new FormData();
            formData.append('file', selectedFile);
            formData.append('email', userEmail);
            formData.append('supplier', supplierName);  // Add supplier name to the form data
    
            try {
                const response = await axios.post('https://skeba.info/costbrain/pdftotext.php', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
    
                const responseData = response.data;
                if (responseData.status === 'success' && responseData.invoiceId) {
                    setUploadMessage('File uploaded successfully');
                    navigate(`/invoices/${responseData.invoiceId}`);
                } else {
                    setUploadMessage(responseData.message || 'Error during upload');
                }
            } catch (error) {
                setUploadMessage('Error uploading file: ' + error.message);
            } finally {
                setIsLoading(false);
            }
        } else {
            setUploadMessage('No file selected or supplier not specified');
        }
    };
    
    
    
    
    

    const deleteInvoice = async (invoiceId) => {
        if (window.confirm("Are you sure you want to delete this invoice?")) {
            setIsLoading(true); // Show loading indicator
            try {
                const response = await axios.delete(`https://skeba.info/costbrain/invoiceDisplay.php?invoiceId=${invoiceId}&email=${encodeURIComponent(userEmail)}`);
    
                if (response.status === 200) { // Check if the HTTP status code is 200 (OK)
                    const responseData = response.data;
                    alert(responseData.message); // Should now correctly display "Invoice deleted successfully."
                    fetchInvoices(); // Fetch the updated list of invoices
                } else {
                    console.error('Error deleting invoice:', response.data.message);
                    alert(response.data.message);
                }
            } catch (error) {
                console.error('Error deleting invoice:', error);
                alert(`Error deleting invoice: ${error.message}`);
            } finally {
                setIsLoading(false); // Hide loading indicator
            }
        }
    };
    
    
    

    const fetchInvoices = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await fetch('https://skeba.info/costbrain/invoiceDisplay.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: userEmail })
            });
            const json = await response.json();
            json.sort((a, b) => sortOrder === "desc" 
                ? new Date(b.Invoice_Date) - new Date(a.Invoice_Date)
                : new Date(a.Invoice_Date) - new Date(b.Invoice_Date)
            );
            setInvoices(json); // This updates the invoice state
            setFilteredInvoices(json); // Updates filtered invoices as well
            setTotal(json.reduce((acc, curr) => acc + parseFloat(curr.Invoice_Total), 0));
        } catch (error) {
            console.error('Error fetching invoices:', error);
        } finally {
            setIsLoading(false);
        }
    }, [userEmail, sortOrder]);
    

    const handleSupplierInputChange = (event) => {
        const input = event.target.value;
        setSupplierName(input);
        if (!input) {
            setSuggestions([]);
        } else {
            const filteredSuggestions = [...new Set(invoices.map(invoice => invoice.Supplier))]
                .filter(supplier => supplier.toLowerCase().includes(input.toLowerCase()));
            setSuggestions(filteredSuggestions);
        }
    };

    const selectSuggestion = (name) => {
        setSupplierName(name);
        setSuggestions([]);
    };
    useEffect(() => {
        fetchInvoices();
    }, [fetchInvoices]);

    useEffect(() => {
        const filtered = invoices.filter(invoice => {
            const invoiceDate = new Date(invoice.Invoice_Date);
            const start = startDate ? new Date(startDate) : new Date('1900-01-01');
            const end = endDate ? new Date(endDate) : new Date('2100-01-01');
            return invoiceDate >= start && invoiceDate <= end;
        });
        setFilteredInvoices(filtered);
        setTotal(filtered.reduce((acc, curr) => acc + parseFloat(curr.Invoice_Total), 0));
    }, [invoices, startDate, endDate]);

    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = filteredInvoices.slice(indexOfFirstInvoice, indexOfLastInvoice);
    const pageNumbers = Array.from({ length: Math.ceil(filteredInvoices.length / invoicesPerPage) }, (_, i) => i + 1);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const toggleSortOrder = () => {
        setSortOrder(prevSortOrder => prevSortOrder === "asc" ? "desc" : "asc");
    };

    return (
       <div className="container mt-4 bg-white p-4 shadow rounded" style={{ maxWidth: 600 }}>
            <h2 className="mb-3 text-center ">Current Invoices</h2>
            <div className="d-flex justify-content-between align-items-center mt-3">
                <span className="text-left">Upload a PDF </span>
                <button className="btn btn-primary" onClick={handleChooseFile}>
                    Choose File
                </button>
                <input ref={fileInputRef} type="file" accept=".pdf" onChange={handleFileChange} style={{ display: 'none' }} />
                {selectedFile && (
                    <>
                        <div className="position-relative">
                            <input
                                type="text"
                                placeholder="Supplier Name"
                                value={supplierName}
                                onChange={handleSupplierInputChange}
                                className="form-control mx-2"
                            />
                            {suggestions.length > 0 && (
                                <ul className="list-group position-absolute w-100">
                                    {suggestions.map((suggestion, index) => (
                                        <li key={index} 
                                            className="list-group-item list-group-item-action"
                                            onClick={() => selectSuggestion(suggestion)}>
                                            {suggestion}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <button className="btn btn-success" onClick={uploadFile}>
                            Upload
                        </button>
                    </>
                )}
                
                <div className="mt-3">
                    {isLoading ? <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div> : <p>{uploadMessage}</p>}
                </div>
            </div>
            <div className="row mb-3 mt-3">
                <div className="col-md-6">
                    <label htmlFor="startDate" className="form-label">Start Date:</label>
                    <input type="date" id="startDate" className="form-control"
                        value={startDate} onChange={e => setStartDate(e.target.value)} />
                </div>
                <div className="col-md-6">
                <label htmlFor="endDate" className="form-label">End Date:</label>
<input type="date" id="endDate" className="form-control"
    value={endDate} onChange={e => setEndDate(e.target.value)} />
                </div>
            </div>
            <button className="btn btn-primary mt-1" onClick={toggleSortOrder}>
                Toggle Sort: {sortOrder === "asc" ? "Ascending" : "Descending"}
            </button>
            <table className="table table-hover mt-3">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Invoice #</th>
                        <th>Supplier</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentInvoices.map((invoice, index) => (
                        <tr key={index} onClick={() => navigate(`/invoices/${invoice.Invoice_Id}`)}>
                            <td>{invoice.Invoice_Date}</td>
                            <td>{invoice.Invoice_Number}</td>
                            <td>{invoice.Supplier}</td>
                            <td>
                                <button className="btn btn-danger actionButton" onClick={(e) => {
                                    e.stopPropagation(); // Prevent row click event
                                    deleteInvoice(invoice.Invoice_Id);
                                }}>
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <nav>
                <ul className="pagination">
                    {pageNumbers.map(number => (
                        <li key={number} className="page-item">
                            <button onClick={() => paginate(number)} className="page-link">
                                {number}
                            </button>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default InvoicesPage;
