import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns'; // Importing the date-fns adapter
import './InvoiceStyles.css';
const ItemDetailsPage = () => {
    const { item_id } = useParams();
    const navigate = useNavigate();
    const [item, setItem] = useState({});
    const [transactions, setTransactions] = useState([]);
    const [selectedMetric, setSelectedMetric] = useState('price');
    const parseUnit = (unit) => {
        if (!unit) return { value: 1, unitType: '' }; // Return default if unit is undefined or null
    
        const cleanedUnit = unit.replace(/\s+/g, ''); // Remove all spaces
        const regex = /(\d+\.?\d*)(x(\d+\.?\d*))?([^\d]+)/i; // Updated regex to capture decimal numbers and optional 'x' multiplier
        const match = cleanedUnit.match(regex);
    
        if (match) {
            const quantity = parseFloat(match[1]); // Parse as float to handle decimals
            const multiplier = match[3] ? parseFloat(match[3]) : 1; // Default multiplier is 1
            const unitType = match[4]; // Capture non-numeric characters as unit type
            return {
                value: quantity * multiplier,
                unitType: unitType
            };
        }
        return { value: 1, unitType: '' }; // Default return if no match
    };
    const getLastKnownPrice = () => {
        return transactions.length > 0 ? transactions[0].Item_Price : 'N/A';
    };

    const costPerUnit = () => {
        const priceString = getLastKnownPrice();
        console.log("pricestring",priceString);
        const price = priceString !== 'N/A' ? parseFloat(priceString) : 0; // Convert price to a float if not 'N/A'
        console.log("price", price);
        if (price === 0) {
            return 'N/A'; // Return 'N/A' if no valid price is available
        }
    
        const { value, unitType } = parseUnit(item.Item_Unit);
        if (value === 0) {
            return 'Unit not specified'; // Guard against division by zero if no unit value
        }
    console.log(value);
        const cost = price / value;
        return `${cost % 1 === 0 ? cost.toFixed(0) : cost.toFixed(2)} per ${unitType.trim()}`;
    };
      const calculatePriceChange = (currentPrice, nextPrice) => {
        if (!nextPrice) return { change: '', symbol: '' }; // No next data, return empty change
        const change = ((currentPrice - nextPrice) / nextPrice) * 100;
        return {
            change: Math.abs(change).toFixed(2), // Format to 2 decimal places
            symbol: change > 0 ? '↑' : change < 0 ? '↓' : ''
        };
    };
    const fetchItemDetails = async () => {
        const response = await fetch(`https://skeba.info/costbrain/ItemsByDate.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ Item_Id: item_id })
        });
        const json = await response.json();
        console.log(json);
        if (json.itemInfo) {
            setItem(json.itemInfo);
            // Sort transactions from newest to oldest
            const sortedTransactions = json.transactions.sort((a, b) =>
                new Date(b.Invoice_Date) - new Date(a.Invoice_Date)
            );
            setTransactions(sortedTransactions);
        }
    };
    

    const options = {
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    tooltipFormat: 'dd/MM/yy', // Corrected format tokens
                    displayFormats: {
                        day: 'dd/MM/yy' // Ensure consistent format
                    }
                },
                title: {
                    display: true,
                    text: 'Date'
                }
            },
            y: {
                title: {
                    display: true,
                    text: `Value of ${selectedMetric}`
                }
            }
        }
    };
    
    
    useEffect(() => {
        fetchItemDetails();
        // eslint-disable-next-line
    }, [item_id]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear().toString().substr(-2)}`;
    };

   const getChartData = () => {
    // Sorting transactions from oldest to newest for the chart
    const sortedForChart = [...transactions].sort((a, b) =>
        new Date(a.Invoice_Date) - new Date(b.Invoice_Date)
    );

    const dates = sortedForChart.map(detail => new Date(detail.Invoice_Date));
    const metrics = sortedForChart.map(detail => {
        switch (selectedMetric) {
            case 'price':
                return detail.Item_Price;
            case 'quantity':
                return detail.Item_Qty;
            case 'total':
                return detail.Item_Total;
            default:
                return 0;
        }
    });

    return {
        labels: dates,
        datasets: [
            {
                label: `Item ${selectedMetric} Over Time`,
                data: metrics,
                fill: false,
                backgroundColor: 'rgb(75, 192, 192)',
                borderColor: 'rgba(75, 192, 192, 0.2)',
            },
        ],
    };
};

    
    return (
        <div style={{ maxWidth: 600, margin: '0 auto' }} className="container mt-4">
        <div className="bg-white p-4 shadow rounded">
            <button onClick={() => navigate('/items')} className="btn btn-primary mt-2">Back to All Items</button>
            <h2 className="mt-4">{item.Item_Name}</h2>
            <div className="item-details">
            <p>Item #: {item.Item_Number}</p>
            <p>Size: {item.Item_Unit}</p>
            <p>Price: ${getLastKnownPrice()}, ${costPerUnit()}</p>

        </div>
        <h4  className="mt-4">Invoices</h4>
            {transactions.length > 0 ? (
                <div>
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Quantity</th>
                                <th>Price</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
    {transactions.map((detail, idx) => {
        // Use the next oldest transaction for comparison (next in the sorted array)
        const nextPrice = idx < transactions.length - 1 ? transactions[idx + 1].Item_Price : null;
        const priceChange = calculatePriceChange(detail.Item_Price, nextPrice);
        return (
            <tr key={idx}>
                <td><a href={`${process.env.REACT_APP_BASE_URL}/invoices/${detail.Invoice_Id}`} style={{ textDecoration: 'none', color: 'inherit' }}>{formatDate(detail.Invoice_Date)}</a></td>
                <td>{detail.Item_Qty}</td>
                <td>
                    {detail.Item_Price}
                    <span style={{ fontSize: 'small', color: priceChange.symbol === '↑' ? 'red' : priceChange.symbol === '↓' ? 'green' : 'black' }}>
                        {priceChange.symbol && `${priceChange.symbol}${priceChange.change}%`}
                    </span>
                </td>
                <td>{detail.Item_Total}</td>
            </tr>
        );
    })}
</tbody>

                    </table>
                    <h4  className="mt-4">Graph</h4>
                    <select className="form-select mb-3" value={selectedMetric} onChange={e => setSelectedMetric(e.target.value)}>
                        <option value="price">Price</option>
                        <option value="quantity">Quantity</option>
                        <option value="total">Total</option>
                    </select>
                    <Line data={getChartData()} options={options} />
                </div>
            ) : <p>No details found.</p>}
        </div>
    </div>
    
    );
    
};

export default ItemDetailsPage;




